import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { AnchorHTMLAttributes } from "react";

import { ButtonVariants } from "~/components/common/button";
import Icon from "~/components/common/icon";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import buttonStyles from "../../common/button.module.scss";
import styles from "./editorial-link.module.scss";

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  entry: Entry<unknown>;
  isSmall?: boolean;
  positionArrow?: "start" | "end";
  lookAsButton?: ButtonVariants["variant"];
};

export default function EditorialLink(props: Props) {
  const router = useRouter();
  if (!props.entry || !isActiveEntry(props.entry)) return null;

  // by default variant is medium
  const { entry, isSmall, positionArrow, lookAsButton, ...rest } = props;

  assertEditorialType<Editorials.Link>(entry, ["link"]);

  function calculateClasses() {
    if (lookAsButton) {
      return [buttonStyles.button, buttonStyles[lookAsButton]];
    } else {
      return [styles.link, isSmall ? styles.linkSmall : styles.linkMedium];
    }
  }

  const inspectorModeLink = contentfulUtils.useInspectorMode(entry);

  return (
    <Link
      {...rest}
      {...inspectorModeLink?.getProps("text")}
      href={textUtils.sanitizeContentfulUrl(entry, router)}
      className={classNames(...calculateClasses(), props.className)}
      target={entry.fields.openOnANewTab ? "_blank" : undefined}
    >
      {entry.fields.icon && isActiveEntry(entry.fields.icon) ? (
        <Icon svgMedia={entry.fields.icon.fields.svgMedia} name={entry.fields.icon.fields.svgIconId} />
      ) : (
        <>
          {!!positionArrow && (
            <span style={{ order: positionArrow == "start" ? 0 : 1 }}>
              <Icon name="arrow-right" className={isSmall ? styles.smallIcon : ""} />
            </span>
          )}
          {lookAsButton ? <span>{entry.fields.text}</span> : entry.fields.text}
        </>
      )}
    </Link>
  );
}
