import React, { ReactNode, useState } from "react";

import { ActionCodeContext, ActionCodeContextProps } from "~/contexts/action-code";
import useTimer from "~/hooks/use-timer";

export const ActionCodeContextProvider = ({ children }: { children: ReactNode }) => {
  const ctx: ActionCodeContextProps = {
    ...useTimer(),
    dialogOpenState: useState(false),
  };

  return <ActionCodeContext.Provider value={ctx}>{children}</ActionCodeContext.Provider>;
};
