import { Entry } from "contentful";
import { MutableRefObject } from "react";

import Editorials from "~/types/editorials";

/**
 * Utility functions for handling accordions.
 *
 * @namespace
 */
const accordionUtils = {
  /**
   * Check all accordions except the selected one. If an open accordion is found, a click is forced on it.
   *
   * @param {HTMLButtonElement} disclosureRef - The selected accordion.
   * @param {MutableRefObject<HTMLButtonElement[]>} refAccordions - The array of accordions.
   *
   * @returns {void}
   */
  handleClosingDisclosures(
    disclosureRef: HTMLButtonElement,
    refAccordions: MutableRefObject<HTMLButtonElement[]>
  ): void {
    const otherRefsWithoutSelected = refAccordions.current?.filter((ref) => {
      return ref !== disclosureRef;
    });

    otherRefsWithoutSelected.forEach((ref) => {
      if (ref?.getAttribute("data-headlessui-state") === "open") {
        ref?.click();
      }
    });
  },
};

export default accordionUtils;
