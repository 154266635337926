import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { useTimer } from "~/hooks/use-timer";

export type ActionCodeContextProps = ReturnType<typeof useTimer> & {
  dialogOpenState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export const ActionCodeContext = createContext<ActionCodeContextProps | null>(null);

export const useActionCodeContext = () => {
  const context = useContext(ActionCodeContext);
  if (!context) {
    throw new Error("useActionCodeContext must be used within an ActionCodeProvider");
  }
  return context;
};
