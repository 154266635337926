import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { Entry } from "contentful";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useRef } from "react";
import { useSetRecoilState } from "recoil";

import { countryDialogOpenedState } from "~/atoms/countryDialog";
import Grid from "~/components/common/grid";
import NewsletterBox from "~/components/common/newsletter-box";
import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import accordionUtils from "~/utils/accordion-utils";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Container from "../common/container";
import EscapeNewLine from "../common/escape-new-line";
import HighlightedText from "../common/highlighted-text";
import Icon from "../common/icon";
import MediaAsset from "../common/media-asset";
import RichText from "../common/rich-text";
import styles from "./footer.module.scss";

type LoyaltyProgramProps = {
  entry: Entry<Editorials.Footer>;
};

export function LoyaltyProgram({ entry }: LoyaltyProgramProps) {
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const {
    loyaltyProgramTitle,
    loyaltyProgramParagraph,
    loyaltyProgramMedia,
    loyaltyProgramLink,
    removeLoyaltyProgram,
  } = entry.fields;

  const inspectorMode = contentfulUtils.useInspectorMode(entry);
  const inspectorModeLoyaltyProgramLink = contentfulUtils.useInspectorMode(loyaltyProgramLink);

  return (
    <>
      {!removeLoyaltyProgram ? (
        <div className={styles.loyaltyProgramContainer}>
          <div className={styles.loyaltyProgramDescription}>
            {loyaltyProgramTitle ? (
              <div
                className={
                  contentfulUtils.isHighlightText(loyaltyProgramTitle)
                    ? styles.loyaltyProgramTitleHighlighted
                    : styles.loyaltyProgramTitle
                }
                {...inspectorMode?.getProps("loyaltyProgramTitle")}
              >
                <HighlightedText text={loyaltyProgramTitle} />
              </div>
            ) : null}
            {loyaltyProgramParagraph && breakpoint === "mobile" ? (
              <div className={styles.loyaltyProgramParagraph} {...inspectorMode?.getProps("loyaltyProgramParagraph")}>
                <EscapeNewLine text={loyaltyProgramParagraph} />
              </div>
            ) : null}
            {loyaltyProgramLink && breakpoint === "mobile" && isActiveEntry(loyaltyProgramLink) ? (
              <Link
                prefetch={false}
                className={styles.loyaltyProgramLink}
                href={textUtils.sanitizeContentfulUrl(loyaltyProgramLink, router)}
                target={loyaltyProgramLink.fields.openOnANewTab ? "_blank" : undefined}
                {...inspectorModeLoyaltyProgramLink?.getProps("text")}
              >
                {loyaltyProgramLink.fields.text}
              </Link>
            ) : null}
          </div>
          {loyaltyProgramMedia && loyaltyProgramLink && isActiveEntry(loyaltyProgramLink) ? (
            <Link
              prefetch={false}
              className={styles.loyaltyProgramImage}
              href={textUtils.sanitizeContentfulUrl(loyaltyProgramLink, router)}
              target={loyaltyProgramLink.fields.openOnANewTab ? "_blank" : undefined}
              {...inspectorMode?.getProps("loyaltyProgramMedia")}
            >
              <MediaAsset entry={loyaltyProgramMedia} className={styles.image} width={80} height={80} />
            </Link>
          ) : null}
          {loyaltyProgramParagraph && breakpoint === "desktop" ? (
            <div className={styles.loyaltyProgramParagraph} {...inspectorMode?.getProps("loyaltyProgramParagraph")}>
              <EscapeNewLine text={loyaltyProgramParagraph} />
            </div>
          ) : null}
          {loyaltyProgramLink && breakpoint === "desktop" && isActiveEntry(loyaltyProgramLink) ? (
            <Link
              prefetch={false}
              className={styles.loyaltyProgramLink}
              href={textUtils.sanitizeContentfulUrl(loyaltyProgramLink, router)}
              target={loyaltyProgramLink.fields.openOnANewTab ? "_blank" : undefined}
              {...inspectorModeLoyaltyProgramLink?.getProps("text")}
            >
              {loyaltyProgramLink.fields.text}
            </Link>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

type Props = {
  entry: Entry<unknown>;
};

export default function Footer(props: Props) {
  assertEditorialType<Editorials.Footer>(props.entry, "footer");
  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  const {
    //Accordions
    accordions,

    //Loyalty program
    removeLoyaltyProgram,

    //banner
    bannerTitle,
    bannerBackground,

    //Follow us - social links
    socialLinks,

    // App and Google store link
    appleAppStoreLink,
    googlePlayStoreLink,
  } = props.entry.fields;

  const breakpoint = useBreakpoint();
  const t = useTranslations();

  const refAccordions = useRef<HTMLButtonElement[]>([]);
  const router = useRouter();
  const languageCode = crossCountryUtils.getCurrentLanguageCode(router);
  const countryCode = crossCountryUtils.getCurrentCountryCode(router);
  const setCountryDialogOpenedState = useSetRecoilState(countryDialogOpenedState);

  function openCountryDialog() {
    setCountryDialogOpenedState(true);
  }

  const inspectorModeAppleAppStoreLink = contentfulUtils.useInspectorMode(appleAppStoreLink);
  const inspectorModeGooglePlayStoreLink = contentfulUtils.useInspectorMode(googlePlayStoreLink);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <Container>
          <Grid>
            <NewsletterBox entry={props.entry} />
            {breakpoint === "desktop" ? <LoyaltyProgram entry={props.entry} /> : null}
            {accordions?.filter(isActiveEntry).map((accordion, index) => {
              const inspectorModeAccordion = contentfulUtils.useInspectorMode(accordion);

              return (
                <Disclosure
                  as="div"
                  key={accordion.sys.id}
                  className={styles[`accordion${index + 1}`]}
                  style={{ gridRow: index === 0 && removeLoyaltyProgram && breakpoint === "desktop" ? 1 : undefined }}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as={breakpoint === "desktop" ? "div" : "button"}
                        ref={(ref) => {
                          if (refAccordions.current && ref) {
                            refAccordions.current[index] = ref;
                          }
                        }}
                        onClick={() =>
                          accordionUtils.handleClosingDisclosures(refAccordions.current[index], refAccordions)
                        }
                        className={styles.accordionButton}
                        {...inspectorModeAccordion?.getProps("title")}
                      >
                        <div className={styles.trigger}>
                          {accordion.fields.icon && isActiveEntry(accordion.fields.icon) ? (
                            <Icon
                              svgMedia={accordion.fields.icon.fields.svgMedia}
                              name={accordion.fields.icon.fields.svgIconId}
                              aria-hidden
                            />
                          ) : null}
                          {accordion.fields.title}
                        </div>
                        {breakpoint === "mobile" ? (
                          <Icon name={open ? "chevron-up" : "chevron-down"} aria-hidden width={16} height={16} />
                        ) : null}
                      </Disclosure.Button>
                      <Disclosure.Panel static className={styles.panel}>
                        <div className={styles.panelInner}>
                          <div className={styles.richTextContainer} {...inspectorModeAccordion?.getProps("content")}>
                            {<RichText text={accordion.fields.content} />}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
            {breakpoint === "mobile" ? <LoyaltyProgram entry={props.entry} /> : null}
          </Grid>
        </Container>
        {bannerTitle ? (
          <div
            className={classNames(
              styles.bannerContainer,
              bannerBackground ? styles[bannerBackground] : undefined,
              contentfulUtils.isHighlightText(bannerTitle) ? styles.bannerTitleHighlighted : undefined
            )}
            {...inspectorMode?.getProps("bannerTitle")}
          >
            <HighlightedText text={bannerTitle} />
          </div>
        ) : null}
        <Container>
          <Grid>
            <div
              className={classNames(
                styles.socialLinksContainer,
                removeLoyaltyProgram ? styles.LoyaltyProgramIsRemove : undefined
              )}
            >
              <span>{t("generic.follow_us_on")}</span>
              <div className={styles.socialLinks}>
                {socialLinks?.filter(isActiveEntry).map((socialLink, i) => {
                  const { icon, openOnANewTab, text } = socialLink.fields;
                  if (!isActiveEntry(icon!)) return null;
                  const inspectorModeSocialLink = contentfulUtils.useInspectorMode(socialLink);

                  const { svgIconId, svgMedia } = icon?.fields!;
                  return (
                    <Link
                      prefetch={false}
                      key={`${socialLink.sys.id}-footer-${i}`}
                      href={textUtils.sanitizeContentfulUrl(socialLink, router)}
                      target={openOnANewTab ? "_blank" : undefined}
                      {...inspectorModeSocialLink?.getProps("url")}
                    >
                      <Icon svgMedia={svgMedia} name={svgIconId} />
                    </Link>
                  );
                })}
              </div>
            </div>
            {appleAppStoreLink || googlePlayStoreLink ? (
              <div
                className={classNames(
                  styles.downloadContainer,
                  removeLoyaltyProgram ? styles.LoyaltyProgramIsRemove : undefined
                )}
              >
                {appleAppStoreLink && appleAppStoreLink.fields?.image && isActiveEntry(appleAppStoreLink) && (
                  <Link
                    prefetch={false}
                    className={styles.goToStore}
                    href={textUtils.sanitizeContentfulUrl(appleAppStoreLink, router)}
                    target={appleAppStoreLink.fields.openOnANewTab ? "_blank" : undefined}
                    {...inspectorModeAppleAppStoreLink?.getProps("url")}
                  >
                    <MediaAsset entry={appleAppStoreLink.fields.image} width={96} height={32} />
                  </Link>
                )}
                {googlePlayStoreLink && googlePlayStoreLink.fields?.image && isActiveEntry(googlePlayStoreLink) && (
                  <Link
                    prefetch={false}
                    className={styles.goToStore}
                    href={textUtils.sanitizeContentfulUrl(googlePlayStoreLink, router)}
                    target={googlePlayStoreLink.fields.openOnANewTab ? "_blank" : undefined}
                    {...inspectorModeGooglePlayStoreLink?.getProps("url")}
                  >
                    <MediaAsset entry={googlePlayStoreLink.fields.image} width={96} height={32} />
                  </Link>
                )}
              </div>
            ) : null}

            <div className={styles.countryContainer}>
              <div className={styles.countryName}>
                <span className={styles.fontBase}>{t("generic.country")}</span>
                <span className={styles.fontHighlighted}>{countryCode}</span>
                <Image
                  unoptimized
                  width={16}
                  height={16}
                  alt={countryCode}
                  src={`/next/assets/flags/${countryCode.toLowerCase()}.svg`}
                />
              </div>
              <div className={styles.countryLanguage}>
                <span className={styles.fontBase}>{t("generic.language")}</span>
                <span className={styles.fontHighlighted}>{languageCode}</span>
              </div>
              <button onClick={() => openCountryDialog()} className={styles.countryChange}>
                <span className={styles.fontBase}>{t("generic.change")}</span>
                <Icon name="arrow-right" width={16} height={16} />
              </button>
            </div>
          </Grid>
        </Container>
      </div>
      <div className={styles.legalContainer}>
        <p className={styles.legalText}>{t("generic.footer_legal_text")}</p>
      </div>
    </footer>
  );
}
