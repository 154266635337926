import { Entry } from "contentful";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";

import HighlightedText from "./highlighted-text";
import styles from "./newsletter-typewriter.module.scss";

type Props = {
  entry: Entry<Editorials.Footer>;
  initialText?: string;
  texts?: string[];
  typingDelay: number;
  deletingDelay: number;
  pauseDelay: number;
};

export default function NewsletterTypewriter({
  entry,
  initialText,
  texts,
  typingDelay,
  deletingDelay,
  pauseDelay,
}: Props) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const t = useTranslations();
  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  const delay = useMemo(() => (isDeleting ? deletingDelay : typingDelay), [isDeleting, typingDelay, deletingDelay]);

  const updateText = useCallback(() => {
    if (isDeleting) {
      if (currentIndex > 0) {
        setCurrentText((prevText) => prevText.slice(0, -1));
        setCurrentIndex((prevIndex) => prevIndex - 1);
      } else {
        setIsDeleting(false);
        setTextIndex((prevIndex) => (prevIndex + 1) % texts!.length);
      }
    } else {
      if (currentIndex < texts![textIndex].length) {
        setCurrentText((prevText) => prevText + texts![textIndex][currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        timeoutRef.current = setTimeout(() => setIsDeleting(true), pauseDelay);
      }
    }
  }, [isDeleting, currentIndex, texts, textIndex, pauseDelay]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!isPaused && texts && texts.length > 1) {
      timeoutRef.current = setTimeout(updateText, delay);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [updateText, delay, isPaused, texts]);

  if (!texts || texts.length <= 1) {
    return (
      <p>
        {initialText && (
          <span
            className={contentfulUtils.isHighlightText(initialText) ? styles.titleHighlighted : styles.title}
            {...inspectorMode?.getProps("title")}
          >
            <HighlightedText text={initialText} />
          </span>
        )}
        {texts && (
          <span className={styles.title} {...inspectorMode?.getProps("titleAnimated")}>
            {texts[0]}
          </span>
        )}
      </p>
    );
  }

  return (
    <>
      <p className={styles.visuallyHidden}>{`${initialText ?? ""} ${texts?.[textIndex] ?? ""}`}</p>
      <button
        aria-label={isPaused ? t("generic.startAnimation") : t("generic.stopAnimation")}
        onClick={() => setIsPaused(!isPaused)}
        className={isPaused ? styles.start : styles.stop}
      >
        {initialText && (
          <span
            className={contentfulUtils.isHighlightText(initialText) ? styles.titleHighlighted : styles.title}
            {...inspectorMode?.getProps("title")}
          >
            <HighlightedText text={initialText} />
          </span>
        )}
        <span className={styles.title} {...inspectorMode?.getProps("titleAnimated")}>
          {currentText}
        </span>
      </button>
    </>
  );
}
